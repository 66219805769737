import isMobile from './is-mobile.js';


//グローバルナビ

const path = [
  '/',
  '/index.php',
  '/index.html'
]
var isTopPage = function (v) {
  return path.includes(v);
}

const setClass = 'is-current';

const globalCurrent = function() {
  $('.l-header-navi-content-list-item .l-header-navi-content-list-item__inner').each(function(){

    const $href = $(this).attr('href')

    //トップページ
    if(location.href.match($href) && isTopPage(location.pathname)) {
      $(this).closest(".l-header-navi-content-list-item").removeClass(setClass);
      $(this).closest(".l-header-navi-content-list-item").addClass(setClass);
      return
    }

    //トップページ以外
    if(location.href.match($href) && location.pathname != "/" && $href != "/" && !isTopPage(location.pathname)) {
      $(this).closest(".l-header-navi-content-list-item").addClass('is-current');
    } else {
      $(this).closest(".l-header-navi-content-list-item").removeClass('is-current');
    }
  });
}

globalCurrent();

