var arg = new Object;
var pair = location.search.substring(1).split('&');
for (var i = 0; pair[i]; i++) {
  var kv = pair[i].split('=');
  arg[kv[0]] = kv[1];
}

// arg.industry
// console.log(arg.industry)

if(arg.industry == 1) {
  $('input[name="industry"][value="ドライバー"]').prop('checked', true);
}
if(arg.industry == 2) {
  $('input[name="industry"][value="倉庫業：古賀市"]').prop('checked', true);
}
if(arg.industry == 3) {
  $('input[name="industry"][value="倉庫業：須恵町"]').prop('checked', true);
}