import swiper from 'swiper';

$('.js-service-imageList').each(function (i, v) {

  var $that = $(this);
  let slide = $that.find(".swiper-slide").length;
  
  if (slide < 4) {
    $that.closest('.service-content-sub-imageList-detail').addClass('is-limit');
  }


  var categoryListCoin = new swiper($that, {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 3,
    speed: 200,
    autoplay: false,
    spaceBetween: 35,
    // observer: true,
    // freeModeSticky: true,
    // freeModeMomentumVelocityRatio: 10,
    // freeModeMomentumRatio: 10,
    // observeParents: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      599: {
        loop: true,
        slidesPerView: 1.4,
        spaceBetween: 15,
        centeredSlides: true,
      }
    },
    preloadImages: false,
    noSwiping: false,
    // slidesPerView: 'auto',
    autoHeight: false,
    allowTouchMove: true,
    navigation: {
      nextEl: '.swiper-residence-next',
      prevEl: '.swiper-residence-prev',
    }

  });

});
