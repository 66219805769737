

import swiper from 'swiper';

var worksMain = new swiper ('.js-image-slider', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  effect: "fade",
  slidesPerView: 'auto',
  speed:1000,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  observer: true,
  preloadImages: false,
  autoHeight: true,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2
  },
});




