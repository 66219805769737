var h = "";

$(window).on('resize', function() {
  h = $('.js-navHeight').outerHeight();
  // var b = $('#js-fullScreenMenuButton').outerHeight();
  // console.log(h);
  // console.log(b);

  $('.l-header-navi').css({
    'height': h - 70 - 30
  })
}).trigger('resize');

$(window).on('scroll', function() {
  var offset = $(this).scrollTop();
  if(offset > h + 100) {
    $('.l-navButton').addClass('is-show');
  } else {
    $('.l-navButton').removeClass('is-show');
    
  }
  // this.console.log(offset) 

})