const UAParser = require('ua-parser-js')
var result = UAParser();

var bs = result.browser.name.replace(/\s+/g, "").toLowerCase();
var ua_type = function() {
  var type = result.device.type;
  var type_result = "";
  if(type == 'mobile') {
    type_result = 'sp';
  } else if (type == 'tablet') {
    type_result = 'tb';
  } else {
    type_result = 'pc';
  }
  return type_result;
}
var ua_model = result.device.model ? result.device.model.replace(/\s+/g, "").toLowerCase() : "";
var ua_os = result.os.name.replace(/\s+/g, "").toLowerCase();

$("body").addClass("ua-" + bs);
$("body").addClass("ua-" + ua_type());
if(ua_model) {
  $("body").addClass("ua-" + ua_model);
}
$("body").addClass("ua-" + ua_os);

